/* MENU PRIMARY
  ----------------------------- */
.menu--primary {
  li {
    @extend .list-reset;
    position: relative;
    display: inline-block;
    padding: 0 .5rem;
    transition: all .8s ease-out; 

    @media #{$breakpoint-xl-mx} {
      width: 100%;
    }

    a {
      display: inline-block;
      text-decoration: none;
      font-family: $mono;
    }
  }
}