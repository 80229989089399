.page-template-homepage {
    .section--slideshow {
        position: fixed;
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        top: 0;
        left: 0;
        right: 0;
        z-index: 0;
        .image--wrapper {
            height: 100vh;
            
            figure {
                height: inherit;
        
                img {
                    object-fit: cover;
                }
                figcaption {
                    display: none;
                }
            }
            
        }
    }
    
}

.category .page__header,
.section--partners .section__title,
.page-template-multimedia .section__title,
.content-area .page {
    border-bottom: 2px solid $light-grey;
    padding-top: 3rem;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    position: relative;
    //font-size: 2.5rem;
    @media #{$breakpoint-sm-mx} {
        //font-size: 1.5rem;
    }

    &::after {
        content: "";
        display: block;
        z-index: 100;
        position: absolute;
        left: 50%;
        margin-left: -30px;
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 0 30px 30px 30px;
        border-color: transparent transparent $light-grey transparent;
        transform: rotate(0deg);
        bottom: -1px;

        display: none;
    }

    &__title {
        text-align: center;
    }
}


.category {
    .page__header {
        &__title {
            text-align: center;
        }
    }
    .post__image {
        figcaption {
            display: none;
        }
    }
    .post__link {
        padding: 1rem;
        background: $accent-color;
        color: #fff;
        display: inline-block;
        margin-top: 1rem;
        &:hover {
            text-decoration: none;
            opacity: 0.8;
        }
    }
}

.single-post {
    figcaption {
        font-size: 0.7rem;
        margin-top: 0.5rem;
        padding-bottom: 0.5rem;
        margin-bottom: 1rem;
        border-bottom: 1px solid $light-grey;
    }
}

.page-template-work-packages {
    .timeline {
        .work-package {
            background: $light-grey;
            padding: 3rem;

            @media (max-width: 650px) {
                padding: 2rem;
                padding-left: 6rem;
                
            }

            &__title {
                margin-top: 0.5rem;
                @media (max-width: 650px) {
                    margin-top: -2.5rem;
                    font-size: 1.5rem;
                }
            }

            .icon {
                width: 3.2rem;
                height: 3.2rem;
                border-radius: 2rem;
                background-color: $accent-color;
                z-index: 10;

                @media (max-width: 650px) {
                    margin-left: -4.5rem;
            
                }
        
                &:after {
                    content: "";
                    width: 1px;
                    height: calc(100% + 2rem);
                    background-color: $accent-color;
                    position: absolute;
                    top: 6rem;
                    left: 4.5rem;
                    z-index: 1;
                    @media (max-width: 650px) {
                        top: 5rem;
                        left: 3rem;
                        height: calc(100% + 1rem);
                    }
                }

                &.icon-1 {
                    background: $accent-color url('../../assets/icons/tasks.svg') center center no-repeat;
                }
                &.icon-2 {
                    background: $accent-color url('../../assets/icons/share-alt.svg') center center no-repeat;
                }
                &.icon-3 {
                    background: $accent-color url('../../assets/icons/chess-knight.svg') center center no-repeat;
                }
                &.icon-4 {
                    background: $accent-color url('../../assets/icons/recycle.svg') center center no-repeat;
                }
                &.icon-5 {
                    background: $accent-color url('../../assets/icons/cogs.svg') center center no-repeat;
                }
                &.icon-6 {
                    background: $accent-color url('../../assets/icons/expand.svg') center center no-repeat;
                }
                &.icon-7 {
                    background: $accent-color url('../../assets/icons/newspaper.svg') center center no-repeat;
                }
                &.icon-8 {
                    background: $accent-color url('../../assets/icons/tasks.svg') center center no-repeat;
                }
                &.icon-9 {
                    background: $accent-color url('../../assets/icons/tasks.svg') center center no-repeat;
                }


                background-size: calc(100% - 1.4rem);
            }
            &:last-child {
                .icon {
                    &:after {
                        display: none !important;
                    }   
                }
            }
         }
    }
}
.page-template-objectives {
    .section--objectives {
        .objective {
          
        }
        figure {
            figcaption {
                display: none;
            }
        }
    }
}

.page-template-multimedia,
.page-template-work-publications {
    .section--multimedia,
    .section--publications {
        .files {
            .file {
                padding: 2rem;
                border: 2px solid $light-grey;
                @media (max-width: 650px) {
                    text-align: center;
                    .flex {
                        flex-direction: column;
                    }
                }
            }

            .file-icon {
                max-width: 2rem;
            }
        }
    }
}


.section--partners {
    .partner__logo {
        width: 100%;
    }
}

.page-template-homepage {
    .section--partners {
        background: #fff;
        padding: 0;
        .section__title {
            color: $accent-color;
            text-transform: uppercase;
            font-family: $title-font;
        }
    }

    .section--news {
        background: $accent-color;
        z-index: 55;
        position: relative;
        .section__title {
            text-transform: uppercase;
            color: #fff;
            margin: 0;

            .cat-item {
                a {
                    color: #fff;
                    padding: 0 1rem;
                }
            }
        }

    }

    .section--introduction {
        margin-top: 100vh;
        background: $light-grey;
        position: relative;
        z-index: 55;
        .section__title {
            font-family: $title-font;
            font-size: 1rem;
            color: $dark-color-alt;
            letter-spacing: 0.2em;
        }
        .section__body {
            border-top: 1px solid $mid-grey;
            border-bottom: 1px solid $mid-grey;
            padding-top: 2rem;
            margin-top: 2rem;
            padding-bottom: 2rem;
            margin-bottom: 2rem;

            .text-columns {
                font-size: 1.6rem;
                column-count: 2;
                column-gap: 2rem;
                p {
                  margin-top: 0;
                  padding-top: 0;
                }

                @media (max-width: 650px) {
                    column-count: 1;
                    font-size: 1.2rem;
                }
            }

        }
    }
}


.page-template-contacts {
    .contacts {
        .contact {
            .contact-inner {
                background: $light-grey;
            }
            &__title {
                text-transform: uppercase;
                font-size: 1.1rem;
                font-family: $sans-serif-alt;
                font-weight: normal;
                text-align: center;
                margin-bottom: 1rem;
                padding-bottom: 1rem;
                &:after {
                    content: "";
                    display: block;
                    max-width: 10rem;
                    margin-left: auto;
                    margin-right: auto;
                    height: 2px;
                    margin-top: 1rem;
                    background: $accent-color;
                }
            }

            &__email {
                &:before {
                    content: "";
                    display: block;
                    width: 4rem;
                    height: 4rem;
                    border-radius: 100%;
                    margin: 0.5rem auto 1rem auto;
                    background: $accent-color url('../../assets/icons/envelope.svg') center center no-repeat;
                    background-size: calc(100% - 2rem);
                }
            }
        }
    }
}

