.footer--main {
    .footer--main-inner {
        background: $footer-color;

        .footer--copyright {
            background: $text-color;
            color: #fff;
        }

        a {
            color: $dark-color;
            text-decoration: underline;
        }
    }
}