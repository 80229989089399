/* HEADER MAIN
  ----------------------------- */
.header--main {
  @extend .top-0;
  z-index: 999;
  background: rgba(242,242,242,0.92);

  .admin-bar & {
    top: 2rem;
  }

  .header__menu-wrapper {
    display: block;
    @media #{$breakpoint-xl-mx} {
      display: none;
    }
  }



  // STICKY
  &.sticky {
    // Something happens
  }

  .header__logo {
    max-width: 11rem;
  }

  #menu-main-menu {
    ul {
      display: none;
    }
    li:hover > ul {
      display: block;
    }
    ul {
      display: none;
      position: absolute;
      top: 81px;
      left: 0;
      z-index: 99999;
      
      text-align: center;
      margin: 0;
      padding: 0;
      padding-top: 20px;

      li {
        display: block;
        padding: 0;
        background: $light-grey;
        &.current-menu-item,
        &.current_page_item {
          min-width: 9rem;
          a {
            color: #fff;
            background: $text-color;
          }
        }
        a {
          font-size: 0.8rem;
          color: $text-color;
          display: block;
          padding: 0.75rem 1.5rem;
          width: 100%;
          white-space: nowrap;
          text-transform: none;

          &:hover {
            color: #fff;
            background: $text-color;
          }
        }

        &.current-post-parent {
          a {
            color: #fff;
            background: $text-color;
          }
        }
      }
    }
    > li {
      position: relative;
      transition: all 0.3s;
      &.current_page_item:not(.no-active),
      &.current-menu-ancestor,
      &:hover {
        background-color: $text-color;
        > a {
          color: #FFF;

          &:after,
          &:before {
            opacity: 1;
          }
        }
      }

      &:not(.menu-item-has-children) {
        > a {
          &:before {
            display: none;
          }
        }
      }
      a {
        text-transform: uppercase;
        font-size: 0.9rem;
        padding: 2rem 0.5rem;
        color: $header-link-color;
        position: relative;
        &:after {
            content: " ";
            position: absolute;
            width: 0;
            border-color: transparent;
            border-top-color: $text-color;
            z-index: 999999999;
            border-style: solid;
            border-top-width: 8px;
            border-bottom-width: 8px;
            border-left-width: 10px;
            border-right-width:10px;
            opacity: 0;
            pointer-events: none;
            box-sizing: border-box;
            top: 100%;
            left: calc(50% - 10px);
            transition: all 0.3s;
            pointer-events: none;
        }

          &:before {
            content: " ";
            position: absolute;
            width: 0;
            border-color: transparent;
            border-bottom-color: $light-grey;
            z-index: 999999999;
            border-style: solid;
            border-top-width: 8px;
            border-bottom-width: 8px;
            border-left-width: 10px;
            border-right-width: 10px;
            opacity: 0;
            pointer-events: none;
            box-sizing: border-box;
            top: 100%;
            left: calc(50% - 10px);
            transition: all 0.3s;
            pointer-events: none;
          }
      }
    }
  }

}

/* HEADER OFFCANVAS
  ----------------------------- */
.header--offcanvas {
  background: #FFF;
  z-index: 99;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;

  &[data-enter-from="top"] {
    left: 0;
    right: 0;
    height: 0;

    &.open {
      padding: 4rem 0;
      padding-top: 9.5rem;
      padding-bottom: 8rem;
      height: 100vh;
    }
  }
  li {
    padding: 0 !important;
    margin: 0;

    &.current-menu-item {
      background-color: $text-color;
      >a {
        color: #FFF;
      }
    }
    
    a {
      font-family: $mono;
      font-size: 1rem;
      color: $text-color;
      padding: 1rem 2rem;
      border-bottom: 1px solid $light-grey;
      display: block !important;
    }
  }
  

  $position: right, left;

  @each $side in $position {
    &[data-enter-from="#{$side}"] {
      bottom: 0;
      #{$side}: -100%;
      transition: all .8s .4s ease-out;
  
      &.open {
        padding: 2rem 1rem 0;
        #{$side}: 0;
        transition: all .8s ease-out;
      }
    } 
  }
}