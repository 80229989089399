/* ARROW ON SELECT
----------------------------- */
.select-arrow {
  &:after {
    content: '';
    display: inline-block;
    @extend .absolute-center-y;
    right: 1rem;
    width: .75rem;
    height: .375rem;
    // background: url('../assets/icons/arrow-down-black.svg') no-repeat center center;
    // background-size: contain;
    pointer-events: none;
  }
}

/* COMMON INPUT FIELDS
  ----------------------------- */
input:not([type="checkbox"]):not([type="submit"]),
textarea, select {
  width: 100%;
  appearance: none;
  border: 1px solid rgba(0,0,0,.15);
  background: rgba(0,0,0,.02);
  padding: .6rem 2rem .6rem 1rem;
  font-family: $sans-serif;
  font-size: $main-font-size;

  &:focus {
    outline: none;
    border: 1px solid rgba(0,0,0,.5);
  }
}

/* WPCF7
  ----------------------------- */
.wpcf7-form {
  .form__header {
    padding-bottom: .25rem;
    border-bottom: 1px solid rgba(0,0,0,.1);
  }

  .form__fields-container {
    margin-left: 10rem;

    @media #{$breakpoint-md-mx} {
      margin-left: 0;
    }
  }

  .form__label {
    font-size: $font--14;
    font-weight: 700;

    span {
      color: red;
    }
  }

  .form__notice {
    padding-top: .3rem;
    font-size: $font--14;
    opacity: .75;
  }

  .form__input {
    &--select {
      @extend .select-arrow;
    }

    .wpcf7-checkbox,
    .wpcf7-acceptance {
      display: inline-block;
      padding-top: .5rem;
      
      .wpcf7-list-item:first-child {
        margin-left: 0;
      }

      label {
        font-size: $font--14;
      }
    }

    .wpcf7-acceptance {
      label {
        font-size: $font--14;
        display: flex;
        flex-wrap: wrap;

        input {
          width: 1rem;
        }

        span {
          display: inline-block;
          line-height: 1.65;
          padding-left: .5rem;
          width: calc(100% - 1rem);
        }
      }
    }

    .wpcf7-list-item {
      margin: 0 1rem 0 0;
      padding-bottom: .5rem;
    }
  }

  .form__submit {
    @media #{$breakpoint-md-mx} {
      justify-content: center;
    }

    .ajax-loader {
      position: absolute;
    }
  }
}