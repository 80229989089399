html {
  font-size: 16px; // 1rem
}

body {
  font-size: $main-font-size;
  font-family: $sans-serif;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: geometricPrecision;
  color: $text-color;
  line-height: 1.42;
  
  &.open {
    overflow: hidden;
  }
}

h1, h2, h3, h4, h5, h6 {
  color: $dark-color;
  margin: 0;
  margin-bottom: 1rem;
  line-height: 1;
  font-family: $title-font;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

* {
  box-sizing: border-box;
}

img, video, figure {
  vertical-align: top;
  max-width: 100%;

  &.cover-image {
    object-fit: cover;
    object-position: center;
    font-family: 'object-fit: cover; object-position: center;';
    height: 100%;
    width: 100%;
  }

  &.contain-image {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }

  &.lazy {
    opacity: .1;
    transition: opacity 0.6s 0.05s ease;
    image-rendering: pixelated;
    
    &.loaded {
      opacity: 1;
      image-rendering: auto;
    }

    &--gif {
      opacity: 1;
    }
  }
}

figure {
  margin: 0;
  height: 100%;
}

// FIX LAZY ON SAFARI
@media not all and (min-resolution:.001dpcm) { @supports (-webkit-appearance:none) {
  img.lazy {
    opacity: 1;
  }
}}

.fluid {
  width: 100%;
  height: auto;
}

.fluid-y {
  height: 100%;
}

.max-width-5 {
  max-width: 80rem; // 1280px
}

.cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.absolute-center-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.absolute-center-y {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.iframe-center {
  @extend .absolute-center;
  width: 100vw;
  height: 56.25vw;
  min-height: 100vh;
  min-width: 177.77vh;
}

.content-area {
  margin-top: 9.2rem;

  @media #{$breakpoint-md-mx} {
    margin-top: 8.5rem;
  }
}

.pointer {
  cursor: pointer;
}

.grecaptcha-badge {
  display: none !important;
}

.button {
  background: $dark-color;
  color: $light-grey;
  text-transform: uppercase;
  padding: 0.7rem 2.1rem;
  transition: opacity 0.3s ease;
  font-size: 0.9;
  &:hover {
    opacity: 0.6;
  }
}

.jquery-modal.blocker {
  z-index: 10001;
  .modal {
    max-width: 50rem;
    figure {
      text-align: center;
      .partner__logo {
        width: 100%;
        height: auto;
        max-width: 20rem;
      }
    }
  }
  
}

#breadcrumbs {
  a {
    color: $accent-color;
  }
}

.text-light {
  color: $light-grey !important;
}

.link-dark {
  color: $dark-color;
}