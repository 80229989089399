// Converted Variables
$breakpoint-md: '(min-width: 52em)' !default;
$breakpoint-lg: '(min-width: 64em)' !default;
$breakpoint-xl: '(min-width: 90em)' !default;

/* Basscss Responsive Padding */
@media #{$breakpoint-md} {

  .md-p15  { padding:        $space-15 }
  .md-pt15 { padding-top:    $space-15 }
  .md-pr15 { padding-right:  $space-15 }
  .md-pb15 { padding-bottom: $space-15 }
  .md-pl15 { padding-left:   $space-15 }
  .md-px15 { padding-left:   $space-15; padding-right:  $space-15 }
  .md-py15 { padding-top:    $space-15; padding-bottom: $space-15 }

  .md-p25  { padding:        $space-25 }
  .md-pt25 { padding-top:    $space-25 }
  .md-pr25 { padding-right:  $space-25 }
  .md-pb25 { padding-bottom: $space-25 }
  .md-pl25 { padding-left:   $space-25 }
  .md-px25 { padding-left:   $space-25; padding-right:  $space-25 }
  .md-py25 { padding-top:    $space-25; padding-bottom: $space-25 }

  .md-p35  { padding:        $space-35 }
  .md-pt35 { padding-top:    $space-35 }
  .md-pr35 { padding-right:  $space-35 }
  .md-pb35 { padding-bottom: $space-35 }
  .md-pl35 { padding-left:   $space-35 }
  .md-px35 { padding-left:   $space-35; padding-right:  $space-35 }
  .md-py35 { padding-top:    $space-35; padding-bottom: $space-35 }

  .md-p50  { padding:        $space-50 }
  .md-pt50 { padding-top:    $space-50 }
  .md-pr50 { padding-right:  $space-50 }
  .md-pb50 { padding-bottom: $space-50 }
  .md-pl50 { padding-left:   $space-50 }
  .md-px50 { padding-left:   $space-50; padding-right:  $space-50 }
  .md-py50 { padding-top:    $space-50; padding-bottom: $space-50 }

  .md-p75  { padding:        $space-75 }
  .md-pt75 { padding-top:    $space-75 }
  .md-pr75 { padding-right:  $space-75 }
  .md-pb75 { padding-bottom: $space-75 }
  .md-pl75 { padding-left:   $space-75 }
  .md-px75 { padding-left:   $space-75; padding-right:  $space-75 }
  .md-py75 { padding-top:    $space-75; padding-bottom: $space-75 }

}

@media #{$breakpoint-lg} {

  .lg-p15  { padding:        $space-15 }
  .lg-pt15 { padding-top:    $space-15 }
  .lg-pr15 { padding-right:  $space-15 }
  .lg-pb15 { padding-bottom: $space-15 }
  .lg-pl15 { padding-left:   $space-15 }
  .lg-px15 { padding-left:   $space-15; padding-right:  $space-15 }
  .lg-py15 { padding-top:    $space-15; padding-bottom: $space-15 }

  .lg-p25  { padding:        $space-25 }
  .lg-pt25 { padding-top:    $space-25 }
  .lg-pr25 { padding-right:  $space-25 }
  .lg-pb25 { padding-bottom: $space-25 }
  .lg-pl25 { padding-left:   $space-25 }
  .lg-px25 { padding-left:   $space-25; padding-right:  $space-25 }
  .lg-py25 { padding-top:    $space-25; padding-bottom: $space-25 }

  .lg-p35  { padding:        $space-35 }
  .lg-pt35 { padding-top:    $space-35 }
  .lg-pr35 { padding-right:  $space-35 }
  .lg-pb35 { padding-bottom: $space-35 }
  .lg-pl35 { padding-left:   $space-35 }
  .lg-px35 { padding-left:   $space-35; padding-right:  $space-35 }
  .lg-py35 { padding-top:    $space-35; padding-bottom: $space-35 }

  .lg-p50  { padding:        $space-50 }
  .lg-pt50 { padding-top:    $space-50 }
  .lg-pr50 { padding-right:  $space-50 }
  .lg-pb50 { padding-bottom: $space-50 }
  .lg-pl50 { padding-left:   $space-50 }
  .lg-px50 { padding-left:   $space-50; padding-right:  $space-50 }
  .lg-py50 { padding-top:    $space-50; padding-bottom: $space-50 }

  .lg-p75  { padding:        $space-75 }
  .lg-pt75 { padding-top:    $space-75 }
  .lg-pr75 { padding-right:  $space-75 }
  .lg-pb75 { padding-bottom: $space-75 }
  .lg-pl75 { padding-left:   $space-75 }
  .lg-px75 { padding-left:   $space-75; padding-right:  $space-75 }
  .lg-py75 { padding-top:    $space-75; padding-bottom: $space-75 }

}

@media #{$breakpoint-xl} {

  .xl-p15  { padding:        $space-15 }
  .xl-pt15 { padding-top:    $space-15 }
  .xl-pr15 { padding-right:  $space-15 }
  .xl-pb15 { padding-bottom: $space-15 }
  .xl-pl15 { padding-left:   $space-15 }
  .xl-px15 { padding-left:   $space-15; padding-right:  $space-15 }
  .xl-py15 { padding-top:    $space-15; padding-bottom: $space-15 }

  .xl-p25  { padding:        $space-25 }
  .xl-pt25 { padding-top:    $space-25 }
  .xl-pr25 { padding-right:  $space-25 }
  .xl-pb25 { padding-bottom: $space-25 }
  .xl-pl25 { padding-left:   $space-25 }
  .xl-px25 { padding-left:   $space-25; padding-right:  $space-25 }
  .xl-py25 { padding-top:    $space-25; padding-bottom: $space-25 }

  .xl-p35  { padding:        $space-35 }
  .xl-pt35 { padding-top:    $space-35 }
  .xl-pr35 { padding-right:  $space-35 }
  .xl-pb35 { padding-bottom: $space-35 }
  .xl-pl35 { padding-left:   $space-35 }
  .xl-px35 { padding-left:   $space-35; padding-right:  $space-35 }
  .xl-py35 { padding-top:    $space-35; padding-bottom: $space-35 }

  .xl-p50  { padding:        $space-50 }
  .xl-pt50 { padding-top:    $space-50 }
  .xl-pr50 { padding-right:  $space-50 }
  .xl-pb50 { padding-bottom: $space-50 }
  .xl-pl50 { padding-left:   $space-50 }
  .xl-px50 { padding-left:   $space-50; padding-right:  $space-50 }
  .xl-py50 { padding-top:    $space-50; padding-bottom: $space-50 }

  .xl-p75  { padding:        $space-75 }
  .xl-pt75 { padding-top:    $space-75 }
  .xl-pr75 { padding-right:  $space-75 }
  .xl-pb75 { padding-bottom: $space-75 }
  .xl-pl75 { padding-left:   $space-75 }
  .xl-px75 { padding-left:   $space-75; padding-right:  $space-75 }
  .xl-py75 { padding-top:    $space-75; padding-bottom: $space-75 }

}
