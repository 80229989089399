@font-face {
  font-family: 'Fira Sans';
  src: url('../../assets/fonts/FiraSans-Thin.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Fira Sans';
  src: url('../../assets/fonts/FiraSans-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Fira Sans';
  src: url('../../assets/fonts/FiraSans-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Fira Mono';
  src: url('../../assets/fonts/FiraMono-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Pirulen Rg';
  src: url('../../assets/fonts/PirulenRg-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* MIXINS
  ----------------------------- */
@mixin linear-responsive($small-size, $large-size, $property:'font-size', $small-width: 375, $large-width: 1680, $gutter: 16) {
  $mobile-offset: calc(#{$large-width} / #{$small-width} / #{$gutter} * (#{$large-size} - #{$small-size}));
  $variable-width: calc((#{$large-size} - (#{$small-size} - #{$mobile-offset})) * #{$gutter} * 100 / #{$large-width});
  #{$property}: calc((1rem * #{$small-size}) - (1rem * #{$mobile-offset}) + (1vw * #{$variable-width}));
}

/* COMMONS
   ----------------------------- */
ul,
ul li {
  list-style-type: disc;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

h1, h2, h3, h4 {
  font-weight: 600;
}

.wysiwyg {
  line-height: 1.4;

  p {
    padding: 0;
    margin: 0 0 1rem;

    &:last-child {
      margin: 0;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    padding: 0;
    margin: 0 0 1rem;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  a:not(.button) {
    text-decoration: underline;
  }

  ul li {
    padding-bottom: .5rem;
  }

  table {
    margin-bottom: 2rem;
    border-collapse: collapse;

    td {
      padding: .5rem .75rem;
      border: 1px solid rgba(255,255,255,.5);
    }
  }
}

.uppercase {
  text-transform: uppercase;
}

.serif {
  font-family: $serif;
}

.title-s {
  font-size: 1.2rem;
}

.title-xl {
  font-size: 2.5rem;
}