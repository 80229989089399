/* COMMON
   ----------------------------- */
.slick-slider.flex {
  .slick-list, 
  .slick-track {
    height: 100%;
  }
  
  .slick-track {
    display: flex;

    .slick-slide {
      height: auto;

      > div,
      > div > * {
        height: 100%;
      }

      > div > *.flex {
        display: flex !important;
      }
    }
  }
}

/* SLIDER ARROW
   ----------------------------- */
.slick-slider .slick-arrow {
  position: absolute;
  display: flex;
  align-items: center;
  appearance: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

/* SLIDER DOTS
  ----------------------------- */
.slick-slider .slick-dots {
  position: absolute;
  margin: 0;
  padding: 0;
  bottom: -2rem;
  left: 50%;
  transform: translateX(-50%);

  li {
    display: inline-block;
    margin: 0 .3rem;

    button {
      border: 0;
      padding: 0;
      appearance: none;
      background: transparent;
      font-size: 0;
      cursor: pointer;
      
      &:focus {
        outline: none;
      }

      &:before {
        content: '';
        display: inline-block;
        @extend .circle;
        height: .8rem;
        width: .8rem;
        border: 1px solid #000;
      }
    }

    &.slick-active {
      button:before {
        background: #000;
      }
    }
  }
}