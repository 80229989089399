/* FONTS
   ----------------------------- */
$sans-serif: 'Fira Sans', sans-serif;
$title-font: var(--title-font);
$sans-serif-alt: 'Pirulen Rg';
$serif: serif;
$mono: 'Fira Mono';

/* SPACINGS
   ----------------------------- */
$main-font-size: 1rem;
$font--32: 2rem;
$font--24: 1.5rem; 
$font--21: 1.3125rem;
$font--18: 1.125rem;
$font--16: $main-font-size;
$font--14: .875rem;

$col--1: 8.33%;

/* COLORS
   ----------------------------- */
/*
$accent-color: #ed1b24;
$dark-color: #191919;
$dark-color-alt: #525252;
$header-color: #eaeaea;
$footer-color: #eaeaea;
$text-color: #707070;
$mid-grey: #cccccc;
$light-grey: #f2f2f2;
*/

$accent-color: var(--accent-color);
$dark-color: var(--dark-color);
$dark-color-alt: var(--dark-color-alt);
$header-color: var(--header-color);
$header-link-color: var(--header-link-color);
$footer-color: var(--footer-color);
$text-color: var(--text-color);
$mid-grey: var(--mid-grey);
$light-grey: var(--light-grey);


/* MEDIA QUERIES
   ----------------------------- */
$breakpoint-sm-mx: '(max-width: 40em)'; // 640px
$breakpoint-md-mx: '(max-width: 52em)'; // 832px
$breakpoint-lg-mx: '(max-width: 64em)'; // 1024px
$breakpoint-xl-mx: '(max-width: 78em)'; // 1248px
$breakpoint-xxl-mx: '(max-width: 90em)'; // 1440px
