.section--social-linkedin,
.section--social-share {
    background: #fff;
    .section-inner {
        background: $footer-color;
    }
}

.section--project-form {
    background: $light-grey;
}

